import React, { useEffect, useState } from 'react';
import { MainLayout } from '../components/Layout/MainLayout';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { useApi } from '../hooks/useApi';
import { GetAllCategories, GetProducts } from '../api/product';
import { useNavigate } from 'react-router-dom';
import { url } from '../utils/url';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
export const ProductsPage = () => {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const GetAllCategoriesApi = useApi(GetAllCategories);
  const GetProductsApi = useApi(GetProducts);

  useEffect(() => {
    GetAllCategoriesApi.sendRequest().then((result) => setCategories(result));
    GetProductsHandler({ s: '', categorySlug: '' });
  }, []);

  const GetProductsHandler = async ({ s = search, categorySlug = '' }) => {
    const result = await GetProductsApi.sendRequest({
      search: s,
      categorySlug,
    });
    setProducts(result[0]);
  };
  return (
    <MainLayout title="Товары">
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <TextField
            label="Поиск"
            fullWidth
            onChange={({ target: { value } }) =>
              GetProductsHandler({ s: value })
            }
            sx={{ mb: 3 }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <Button
            variant="outlined"
            sx={{ mb: 2 }}
            onClick={() => navigate(url?.createCategory)}
          >
            Добавить категорию
          </Button>
          {categories?.map((category) => (
            <Stack
              key={category.id}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              onClick={() =>
                GetProductsHandler({
                  s: search,
                  categorySlug: category.slug,
                })
              }
            >
              <Box>
                <Typography>{category.name}</Typography>
              </Box>
              <Stack>
                <IconButton
                  onClick={() => navigate(`/products/${category.slug}`)}
                >
                  <CreateOutlinedIcon />
                </IconButton>
              </Stack>
            </Stack>
          ))}
        </Grid>
        <Grid item xs={12} lg={9}>
          <Button
            variant="outlined"
            onClick={() => navigate(url?.createProduct)}
            sx={{ mb: 2 }}
          >
            Добавить товар
          </Button>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Название</TableCell>
                  <TableCell align="right">Дата обновления</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((product) => (
                  <TableRow
                    key={product.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    onClick={() =>
                      navigate(
                        `/products/${product.category.slug}/${product.slug}`,
                      )
                    }
                  >
                    <TableCell component="th" scope="row">
                      {product.name}
                    </TableCell>
                    <TableCell align="right">
                      {format(product.updated, 'dd.MM.yyyy HH:ss:ii')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </MainLayout>
  );
};
